import { default as consultation_45requestsrNlWh39qW0Meta } from "/vercel/path0/pages/consultation-requests.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as force_45password_45changemtEY7eZTWhMeta } from "/vercel/path0/pages/force-password-change.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as leadsWP8xhf6a9nMeta } from "/vercel/path0/pages/leads.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as qualification_45form8VAvLWv9CSMeta } from "/vercel/path0/pages/qualification-form.vue?macro=true";
import { default as request_45consultationhyDFwwS3neMeta } from "/vercel/path0/pages/request-consultation.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as _91id_93H90MXeekwMMeta } from "/vercel/path0/pages/user-settings/[id].vue?macro=true";
import { default as index5IV56XJQXYMeta } from "/vercel/path0/pages/user-settings/my-subscriptions/index.vue?macro=true";
import { default as newjTjgLGrQncMeta } from "/vercel/path0/pages/user-settings/my-subscriptions/new.vue?macro=true";
import { default as _91id_931KGDAoqrNoMeta } from "/vercel/path0/pages/user-settings/profile-setup/[id].vue?macro=true";
import { default as cpa8Tkss0fkHAMeta } from "/vercel/path0/pages/users/cpa.vue?macro=true";
import { default as financial_45advisor2RyO3xCJAEMeta } from "/vercel/path0/pages/users/financial-advisor.vue?macro=true";
export default [
  {
    name: "consultation-requests",
    path: "/consultation-requests",
    component: () => import("/vercel/path0/pages/consultation-requests.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "force-password-change",
    path: "/force-password-change",
    meta: force_45password_45changemtEY7eZTWhMeta || {},
    component: () => import("/vercel/path0/pages/force-password-change.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "leads",
    path: "/leads",
    component: () => import("/vercel/path0/pages/leads.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "qualification-form",
    path: "/qualification-form",
    meta: qualification_45form8VAvLWv9CSMeta || {},
    component: () => import("/vercel/path0/pages/qualification-form.vue").then(m => m.default || m)
  },
  {
    name: "request-consultation",
    path: "/request-consultation",
    meta: request_45consultationhyDFwwS3neMeta || {},
    component: () => import("/vercel/path0/pages/request-consultation.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-id",
    path: "/user-settings/:id()",
    component: () => import("/vercel/path0/pages/user-settings/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-settings-my-subscriptions",
    path: "/user-settings/my-subscriptions",
    component: () => import("/vercel/path0/pages/user-settings/my-subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-my-subscriptions-new",
    path: "/user-settings/my-subscriptions/new",
    component: () => import("/vercel/path0/pages/user-settings/my-subscriptions/new.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-profile-setup-id",
    path: "/user-settings/profile-setup/:id()",
    meta: _91id_931KGDAoqrNoMeta || {},
    component: () => import("/vercel/path0/pages/user-settings/profile-setup/[id].vue").then(m => m.default || m)
  },
  {
    name: "users-cpa",
    path: "/users/cpa",
    component: () => import("/vercel/path0/pages/users/cpa.vue").then(m => m.default || m)
  },
  {
    name: "users-financial-advisor",
    path: "/users/financial-advisor",
    component: () => import("/vercel/path0/pages/users/financial-advisor.vue").then(m => m.default || m)
  }
]